/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import fetchUserSettings from '../settings/fetchSettings';
import fetchGeneralSystemSettings from './fetchGeneralSystemSettings';
import initGeneralSystemSettings from './initGeneralSystemSettings';
import initUserSettings from '../settings/initUserSettings';

export default () => {
    return Promise.all([fetchGeneralSystemSettings(), fetchUserSettings()])
        .then(([generalSystemSettings, userSettings]) => {
            initGeneralSystemSettings(generalSystemSettings);
            initUserSettings(userSettings);
        }).catch((error) => {
            console.warn(`Initializing settings has failed with error: ${error}.`);
            AV.internal.generalSystemSettings = {};
            AV.internal.userSettings = {};
        });
};
