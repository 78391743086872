export default () => {
    return new Promise((resolve) => {
        function onLoad() {
            resolve();
            window.removeEventListener('load', onLoad);
        }

        if (document.readyState === 'complete') {
            resolve();
        } else {
            window.addEventListener('load', onLoad);
        }
    });
};
