// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".contentPane{display:flex}.src-js-plugins-com-avid-central-UICore-main-js-layout-___root__incorrect-chrome-version___8I9BY{display:block;height:30%;width:90%;color:#DADDEB;margin:20% 0 20% 7%;font-family:Arimo, Arial, sans-serif}.src-js-plugins-com-avid-central-UICore-main-js-layout-___root__incorrect-chrome-version___8I9BY h1{font-weight:100;font-size:36px;padding-bottom:10px}.src-js-plugins-com-avid-central-UICore-main-js-layout-___root__incorrect-chrome-version___8I9BY h3{padding-top:20px;font-weight:100;font-size:18px}\n", "",{"version":3,"sources":["root.scss"],"names":[],"mappings":"AAIA,aACI,YAAa,CAChB,iGAGG,aAAc,CACd,UAAW,CACX,SAAU,CACV,aAAc,CACd,mBAAoB,CACpB,oCAAqC,CANzC,oGASQ,eAAgB,CAChB,cAAe,CACf,mBAAoB,CAX5B,oGAeQ,gBAAiB,CACjB,eAAgB,CAChB,cAAe","file":"root.scss","sourcesContent":["/*\n* Copyright 2019 by Avid Technology, Inc.\n*/\n\n:global(.contentPane) {\n    display: flex;\n}\n\n.incorrect-chrome-version {\n    display: block;\n    height: 30%;\n    width: 90%;\n    color: #DADDEB;\n    margin: 20% 0 20% 7%;\n    font-family: Arimo, Arial, sans-serif;\n\n    h1 {\n        font-weight: 100;\n        font-size: 36px;\n        padding-bottom: 10px;\n    }\n\n    h3 {\n        padding-top: 20px;\n        font-weight: 100;\n        font-size: 18px;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"incorrect-chrome-version": "src-js-plugins-com-avid-central-UICore-main-js-layout-___root__incorrect-chrome-version___8I9BY"
};
module.exports = exports;
