/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import { DEFAULT_LOCALE } from '../../util/config';

const headers = new Headers();
headers.append('Content-Type', 'text/plain');

export default function () {
    const lang = AV.internal.userSettings.locale || DEFAULT_LOCALE;
    return fetch(`/license/${lang}/LICENSE.txt`, {
        method: 'get',
        headers,
        credentials: 'same-origin'
    })
        .then((response) => {
            return response.text();
        })
        .catch(() => {
            console.warn('[EULA] Failed to get license text.');
        });
}
