/*
 * Copyright 2020 by Avid Technology, Inc.
 */

const urlFlags = {
    LIMITED: '#limited',
    LIMITED_IN: '#limited_',
};

export default () => {
    const { hash } = window.location;
    const limitedInIndex = hash.indexOf(urlFlags.LIMITED_IN);
    if (limitedInIndex > -1) {
        const fullHashTag = hash.slice(limitedInIndex);
        const [, embeddedIn] = fullHashTag.split('_');
        if (embeddedIn) {
            return { mode: 'embedded', embeddedIn: embeddedIn.toLowerCase() };
        }
    }
    if (hash.indexOf(urlFlags.LIMITED) > -1) {
        return { mode: 'embedded', embeddedIn: 'composer' };
    }
    return null;
};
