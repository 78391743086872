/*
 * Copyright 2017 by Avid Technology, Inc.
 */

import { get } from './http-requests';
import { filterBrowsableSystems } from './utils';
import config from './config';

/**
 * Registry class.
 * Class Registry provides base API for CTMS Registry.
 * getServiceRoots - returns raw service root call.
 * getSystems - returns list of systems with links for all available resources.
 * getBrowsableSystems - returns list of browsable systems with links for all available resources.
 */
class Registry {

    constructor() {
        this.serviceRoots = config.serviceRoots;
    }

    /**
     * Returns service roots.
     * @param config.forceRefresh  To force receiving data from backend (not from cached version)
     * @returns {Promise} raw response.
     */
    getServiceRoots({ forceRefresh } = {}) {
        const rootsFromCache = window.AV.ServiceRoots && window.AV.ServiceRoots.get();
        const isCacheDefined = rootsFromCache && rootsFromCache.systems;
        if (isCacheDefined && !forceRefresh) {
            return Promise.resolve(rootsFromCache);
        }
        return get(this.serviceRoots);
    }

    /**
     * Returns systems with existing links from resources.
     * @returns {Promise} Available systems.
     */
    getSystems() {
        return this.getServiceRoots().then(response => {
            return this.extractSystems(response);
        });
    }
    
    /**
     * Extract data about systems with corresponding links from resources
     * @param {Object} serviceRoots /serviceroots call response data
     * @returns {Array<Object>} Available systems.
     */
    extractSystems(serviceRoots) {
        const systems = serviceRoots.systems || [];
        const resources = serviceRoots.resources;
        return systems.map((system) => {
            return  {
                ...system,
                _links: getSystemResources(resources, system)
            };
        });
    }
    
    /***
     * Returns browsable systems.
     * System is not available for browsing if there is no this system in both loc:locations and loc:root-item systems in resources.
     * @returns {Promise} Available filtered systems.
     */
    getBrowsableSystems() {
        return this.getServiceRoots().then(response => {
            let systems = response.systems || [];
            const resources = response.resources;
            const availableSystems = systems.filter(system => filterBrowsableSystems(resources, system));
            return availableSystems.map((system) => {
                system._links = getSystemResources(resources, system);
                return system;
            });
        });
    }
}

/**
 * Returns resources for specified system.
 * @param {Object} resources Resources from the response.
 * @param {Object} system System.
 * @returns {Object} List of resources which can be applicable for system.
 */
function getSystemResources(resources, system) {
    let result = {};
    Object.entries(resources).forEach(([resourceName, resource]) => {
        const resources = getResource(resource, resourceName, system);
        Object.assign(result, resources);
    });
    return result;
}

/**
 * Returns resource items.
 * @param {Object} resource Resource.
 * @param {String} resourceName Resource name.
 * @param {Object} system System.
 * @returns {Object} Resource items.
 */
function getResource(resource, resourceName, system) {
    let result = {};
    resource.forEach((resourceItem) => {
        const resourceSystems = resourceItem.systems;
        const links = getResourceItem(resourceSystems, system, resourceItem, resourceName);
        if (links) {
            Object.assign(result, links);
        }
    });
    return result;
}

/**
 * Returns resource item filtered provided system.
 * @param {Array} resourceSystems List of systems for which the resource is applicable
 * @param {Object} system System.
 * @param {Object} resource Resource.
 * @param {String} resourceName Resource name.
 * @returns {Object} Resource item.
 */
function getResourceItem(resourceSystems, system, resource, resourceName) {
    let result = {};
    resourceSystems && resourceSystems.forEach((loc) => {
        if (loc.systemType === system.systemType && loc.systemID === system.systemID) {
            let { systems:_, ...obj } = resource; //NOSONAR
            result[resourceName] = obj;
        }
    });
    return result;
}

export default new Registry();
