/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import { AttributesService } from 'avid-ui-acs-attributes';

export default () => {
    return AttributesService.fetch('com.avid.central.GeneralSystemSettings').then((result) => {
        return (result && result.value) || {};
    }).catch((error) => {
        console.warn('Reading of general system settings has failed with error:', error);
        return {};
    });
};
