/*
 * Copyright 2019-2020 by Avid Technology, Inc.
 */
/**
 * @author anna.kryzhko
 */

import { get, post } from './http-requests';
import {
    getLinks,
    getLinkByBaseData, getSimpleLink,
} from './utils';
import Registry from './registry';

const getLocale = () => {
    return window.AV.User.locale;
};

export default class Taxonomy {
    constructor(config) {
        this.taxonomy = config.taxonomy;
    }
    
    /**
     * Returns taxonomy by base data.
     * @param {Object} baseData Taxonomy data.
     * @param {String} baseData.systemID taxonomy system id.
     * @param {String} baseData.systemType taxonomy system type.
     * @param {String} baseData.id taxonomy id.
     * @returns {Promise} Taxonomy.
     */
    static getByBaseData(baseData) {
        return this.getLinks().then(links => {
            let link = getLinkByBaseData(links, baseData);
            if (!link || !link.href) {
                return Promise.reject({
                    errorMessage: 'Link taxonomies:taxonomy-by-taxonomyid do not exist for the system'
                });
            }
            link = link.href.replace('{taxonomyid}', encodeURIComponent(baseData.id));
            return get(link);
        });
    }
    /**
     * Checks if entry has child entries
     * @static
     * @param {Object} config
     * @param {Object} config.parentEntry  Taxonomy Entry resource
     * @returns {boolean} True if there is link to taxonomies:child-entries
     */
    static hasChildEntries({ parentEntry }) {
        return Boolean(getSimpleLink(parentEntry, 'taxonomies:child-entries'));
    }
    /**
     * Get child entries for specified entries
     * Returns Promise fulfilled with taxonomies:entries
     * @static
     * @param config
     * @param {Number} config.parentEntry Taxonomy Entry resource
     * @param {Number} config.limit   Limit
     * @param {Number} config.offset  Offset
     * @returns {Promise} taxonomies ranges from the requested range
     */
    static getChildEntries({ parentEntry, offset, limit }) {
        const lang = getLocale();
        const queryParams = { offset, limit, lang };
        const link = getSimpleLink(parentEntry, 'taxonomies:child-entries');
        if (!link) {
            return Promise.reject({
                errorMessage: 'Link taxonomies:child-entries do not exist'
            });
        }
        return get(link, queryParams);
    }
    /**
     * Returns taxonomies:taxonomy-by-taxonomyid resource
     * @returns {Promise} Array with taxonomies:taxonomy-by-taxonomyid links for CTC systems
     */
    static getLinks() {
        return Registry.getServiceRoots()
            .then(response => {
                const links = response.resources['taxonomies:taxonomy-by-taxonomyid'];
                return getLinks(links);
            });
    }
    
    /**
     * Returns Promise fulfilled with taxonomies:entries
     * @param config
     * @param {Number} config.limit
     * @param {String} config.offset
     * @returns {Promise} taxonomies ranges from the requested range
     */
    getEntries(config) {
        const lang = getLocale();
        const queryParams = { ...config, lang };
        const link = getSimpleLink(this.taxonomy, 'taxonomies:entries');
        if (!link) {
            return Promise.reject({
                errorMessage: 'Link taxonomies:entries do not exist'
            });
        }
        return get(link, queryParams);
    }

    /**
     * Returns Promise fulfilled with taxonomies:entries
     * @param {Array} ids ids to get taxonomies entries for
     * @returns {Promise} taxonomies entries
     */
    getEntriesByIds(ids) {
        if (!ids || ids.length === 0) {
            return Promise.reject({
                errorMessage: 'ids to fetch couldn\'t be empty',
            });
        }

        const lang = getLocale();
        const queryParams = { lang, embed: 'path' };
        const link = getSimpleLink(this.taxonomy, 'taxonomies:entry-by-entryid-bulk');
        if (!link) {
            return Promise.reject({
                errorMessage: 'Link taxonomies:entry-by-entryid-bulk do not exist'
            });
        }
        return post(link, ids, queryParams);
    }

    /**
     * Returns Promise with taxonomies:entries
     * @param phrase to search for
     * @param config with query params
     * @returns {Promise} taxonomies ranges from the requested range
     */
    search(phrase, config = {}) {
        const lang = getLocale();
        const body = {
            'quick': {
                'query': phrase,
                'scope': {
                    'label': true,
                    'attributes': [ 'synonyms' ]
                }
            }
        };

        const queryParams = { lang, embed: 'path', ...config };
        const link = getSimpleLink(this.taxonomy, 'taxonomies:search');
        if (!link) {
            return Promise.reject({
                errorMessage: 'Link taxonomies:search do not exist'
            });
        }
        return post(link, body, queryParams);
    }
}
