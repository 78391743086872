/*
 * Copyright 2017 by Avid Technology, Inc.
 */

import { get } from './http-requests';
import { getDataModel } from './utils';
import Registry from './registry';

/**
 * Data provider class.
 * Provides base API for working with aggregated data model from CTMS Registry.
 * getAggregatedModel - returns aggregated data model.
 * getAttributes - returns attributes.
 */
class DataModel {

    /**
     * Returns aggregated model.
     * @returns {Promise} aggregated model.
     */
    getAggregatedModel() {
        return Registry.getServiceRoots().then((response) => {
            return getDataModel(response);
        });
    }

    /**
     * Returns list of attributes.
     * @param {String} language Language.
     * @returns {Promise} List of attributes.
     */
    getAttributes(language) {
        return this.getAggregatedModel()
            .then((result) => {
                const dataModelLink = result.href;
                if (!dataModelLink) {
                    return Promise.reject({
                        errorMessage: 'Link datamodel:aggregated-model do not exist'
                    });
                }
                const attributesUrl = getAttributeUrlByLang(dataModelLink, language);
                return get(attributesUrl);
            });
    }
}

/**
 * returns attribute url for specified language.
 * @param {String} url Attribute url with template.
 * @param {String} language Language in format en-us.
 */
function getAttributeUrlByLang(url, language) {
    const languageQueryStr = language ? `?lang=${language}` : '';
    return url.replace('{?lang}', languageQueryStr);
}

export default new DataModel();
