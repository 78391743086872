/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import getLoginLink from '../../identity-provider/get-identity-provider-link';
import login from '../form/login-call';
import onLoginResponse from './onLoginResponse';

export default ({ username, password, isAdministrator }) => {
    return getLoginLink().then(({ link, message, isValid }) => {
        if (!isValid) {
            return { isValid, message };
        }
        return login({
            username,
            password,
            link,
            isAdminLogin: isAdministrator
        }).then((response) => {
            return onLoginResponse({ response, isAdministrator });
        });
    });
};
