/*
 * Copyright 2017-2018 by Avid Technology, Inc.
 */
import UserSettings from 'avid-ui-iam-user-settings';
import { key, kind } from '../../../configs/userSettings';

export default () => {
    return UserSettings.fetch(kind, key)
        .then(({ entity }) => {
            return (entity && entity[0] && entity[0].value) || {};
        }).catch((error) => {
            console.warn(`Reading of settings is failed with error: ${error}. Default settings are set`);
            return {};
        });
};
