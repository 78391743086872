/*
 * Copyright 2018 by Avid Technology, Inc.
 */
/**
 * @author anna.kryzhko
 */

import { getFatalErrorDom, getAccessDeniedErrorDom } from './getFatalErrorDom';
import loadFatalErrorStyles from './loadFatalErrorStyles';

const handleFatalError = (err) => {
    console.error(err);
    let text = (err && typeof err === 'object') ? err.message : err;
    text = text ? `Error: ${text}` : 'An error occurred in the application.';
    document.body.innerHTML = '';
    loadFatalErrorStyles();
    document.body.appendChild(getFatalErrorDom(text));
};
const handleAccessDeniedError = (err) => {
    console.error(err);
    let text = (err && typeof err === 'object') ? err.message : err;
    text = text ? `Error: ${text}` : 'Access Denied!';
    document.body.innerHTML = '';
    loadFatalErrorStyles();
    document.body.appendChild(getAccessDeniedErrorDom(text));
};

export {
    handleFatalError,
    handleAccessDeniedError,
};
