/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import fetchIdentityProviders from './fetch-identity-providers';
import getDefaultProviderLink from './get-default-provider-link';

export default () => {
    return fetchIdentityProviders()
        .then((response) => {
            const { status } = response;
            if (status === 200) {
                return response.json()
                    .then(getDefaultProviderLink);
            }
            return {
                isValid: false,
                message: '<p>Failed to get identity providers.</p><p>Please contact your Administrator.</p>'
            };
        })
        .catch((err) => {
            const errorMessage = '[Identity provider] Failed to get default identity provider.';
            console.warn(errorMessage, err);
            throw err;
        });
};
