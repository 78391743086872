/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import UserSettings from 'avid-ui-iam-user-settings';
import { eulaKind, eulaKey } from '../../util/config';
import getAppVersion from '../../util/application-version';
import initSettings from '../../settings/initSettings';

const ACCESS_DENIED = 403;

export default () => {
    return Promise.all([
        getAppVersion(),
        UserSettings.fetch(eulaKind, eulaKey),
        initSettings()
    ])
    .then(([{ version, build }, eulaSetting]) => {
        if (!window.AV) {
            window.AV = {};
        }
        window.AV.internal = window.AV.internal || {};
        window.AV.internal.version = version;
        window.AV.internal.build = build;
        let userEulaVersion;
        if (!eulaSetting) {
            console.error('[Eula] There is no eula settings or they are empty');
        } else {
            userEulaVersion = eulaSetting.entity && eulaSetting.entity[0]
                && eulaSetting.entity[0].value && eulaSetting.entity[0].value.version;
        }
        return {
            eulaVersion: version,
            userEulaVersion
        };
    })
    .catch((err) => {
        if (err && err.status === ACCESS_DENIED) {
            console.error('Access denied for requesting settings', err);
            return Promise.reject(new Error('accessDenied'));
        }
        console.error('Failed to initialize settings', err);
        return Promise.reject(new Error('Failed to initialize settings'));
    });
};
