/*
 * Copyright 2017 by Avid Technology, Inc.
 */

import { get } from './http-requests';
import { getLocationLink, getRootItemLink } from './utils';

/**
 * System class.
 * Class System provides API for working with CTMS systems.
 * getRoot - returns root.
 * getRootItem - returns root node.
 */
export default class System {

    constructor(config) {
        this.system = config.system;
    }

    /**
     * Returns system root.
     * @returns {Promise} Root node.
     */
    getRoot() {
        const systemUrl = getLocationLink(this.system);
        if (!systemUrl) {
            return Promise.reject({
                errorMessage: 'Link loc:locations do not exist'
            });
        }
        return get(systemUrl);
    }

    /**
     * Returns system root item.
     * @returns {Promise} Root item.
     */
    getRootItem() {
        return this.getRoot()
            .then((root) => {
                const rootItemLink = getRootItemLink(root);
                if (!rootItemLink) {
                    return Promise.reject({
                        errorMessage: 'No loc:root-item link'
                    });
                }
                return get(rootItemLink);
            });
    }
}
