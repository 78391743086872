/*
 * Copyright 2018-2019 by Avid Technology, Inc.
 */

import loginTpl from '../view/login-tpl';

function el(id) {
    return document.getElementById(id);
}

function setInnerText(el, text) {
    if (el.firstChild) {
        el.replaceChild(document.createTextNode(text), el.firstChild);
    } else {
        el.appendChild(document.createTextNode(text));
    }
}

function setContent(el, str) {
    el.innerHTML = str;
    el.style.display = 'block';
}

function hide(el) {
    el.innerHTML = '';
    el.style.display = 'none';
}

function triggerAnimation(el) {
    const anim1 = 'mcux-login-error-anim-1';
    const anim2 = 'mcux-login-error-anim-2';
    let nextAnim = anim1;

    if (el.classList.contains(anim1)) {
        el.classList.remove(anim1);
        nextAnim = anim2;
    }

    if (el.classList.contains(anim2)) {
        el.classList.remove(anim2);
        nextAnim = anim1;
    }

    el.classList.add(nextAnim);
}

let template;
const adminPageClassName = 'admin-login-page';

const loginForm = {
    showRequestError(text) {
        el('mcux-login-loading-inner').innerHTML = text || '<p>Error while accessing the back-end server.</p><p>Please try to reload this page in a minute.</p>';
        el('mcux-login-loading-outer').style.display = 'flex';
        el('mcux-login-form').style.visibility = 'hidden';
    },

    showLoadingMessage(text) {
        const msg = text || '...';
        setInnerText(el('mcux-login-loading-inner'), msg);
        el('mcux-login-loading-outer').style.display = 'flex';
        el('mcux-login-form').style.visibility = 'hidden';
    },

    cleanError() {
        hide(el('mcux-login-error'));
    },

    showError(str) {
        const errEl = el('mcux-login-error');
        setContent(errEl, str);
        triggerAnimation(errEl);
    },

    setBrowserWarning(browserWarning) {
        setInnerText(el('mcux-login-browser-message'), browserWarning);
        el('mcux-login-browser-notice').style.display = 'block';
    },

    hideLoading() {
        el('mcux-login-loading-outer').style.display = 'none';
    },

    show() {
        el('mcux-login-form').style.visibility = 'visible';
    },

    onSubmit(callback) {
        el('mcux-login-form').onsubmit = callback;
    },

    getName() {
        return el('name').value;
    },

    getPassword() {
        return el('password').value;
    },

    render() {
        template = loginTpl();
        document.body.innerHTML = '';
        document.body.appendChild(template);
    },

    destroy() {
        template.remove();
        template = null;
        const adminBackground = document.querySelector('.admin-login-page');
        if (adminBackground) {
            adminBackground.parentNode.removeChild(adminBackground);
        }
    },

    renderSpinner() {
        const spinner = document.createElement('div');
        spinner.setAttribute('id', 'avid-nux-page-load-indicator');
        spinner.setAttribute('class', 'nux-loading-spinner-large');
        document.body.appendChild(spinner);
    },

    addLoginAdminStyle() {
        const adminHeader = el('login-admin-header');
        adminHeader.style.display = 'block';
        const adminBackground = document.createElement('div');
        adminBackground.classList.add(adminPageClassName);
        el('login-page').insertBefore(adminBackground, el('logo'));
    }
};

export default loginForm;
