/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import { loginErrors } from '../messages';

const isErrorType = (errorType, { code, error }) => {
    return errorType.error
        ? errorType.error === error
        : errorType.code === code;
};

const getFailureMessage = (status, data) => {
    const { code, error, params } = data;
    let errors = Object.values(loginErrors).filter(it => it.status === status);

    if (params && params.reason) {
        errors = errors.filter(it => it.params && it.params.reason === params.reason);
    }

    const errorToShow = errors.find(it => isErrorType(it, { code, error }));
    console.warn('Login failed', status, data);
    return (errorToShow && errorToShow.message) || error;
};

export default getFailureMessage;

