/*
 * Copyright 2017, 2020 by Avid Technology, Inc.
 */

// region Methods: Public

import getHeaders from './getHeaders';

function getCredentials() {
    return 'same-origin';
}

function get(url, params) {
    const urlWithParams = addParamsToUrl(url.split('?')[0], params);
    return fetch(urlWithParams, {
        method: 'GET',
        credentials: getCredentials(),
        headers: getHeaders(),
    })
        .then(status)
        .catch(error);
}

function post(url, body, params) {
    const urlWithParams = addParamsToUrl(url.split('?')[0], params);
    return fetch(urlWithParams, {
        method: 'POST',
        mode: 'cors',
        credentials: getCredentials(),
        headers: getHeaders(),
        body: JSON.stringify(body)
    })
        .then(status)
        .catch(error);
}

function patch(url, body, params) {
    const urlWithParams = addParamsToUrl(url.split('?')[0], params);

    return fetch(urlWithParams, {
        method: 'PATCH',
        mode: 'cors',
        credentials: getCredentials(),
        headers: getHeaders(),
        body: JSON.stringify(body),
    })
        .then(status)
        .catch(error);
}

function remove(url, body, params) {
    const urlWithParams = addParamsToUrl(url.split('?')[0], params);
    return fetch(urlWithParams, {
        method: 'DELETE',
        mode: 'cors',
        credentials: getCredentials(),
        headers: getHeaders(),
        body: JSON.stringify(body),
    })
        .then(response => response.status === 204 ? Promise.resolve() : Promise.reject(response))
        .catch(error);
}

// endregion

// region Methods: Private

function status(response) {
    if (response.status >= 200 && response.status < 300 || response.ok) {
        return Promise.resolve(response.json());
    }
    return Promise.reject(response);
}

function addParamsToUrl(url, params) {
    const paramString = params && Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');
    if (paramString) {
        url += `?${paramString}`;
    }
    return url;
}

function error(err = {}) {
    return new Promise((resolve, reject) => {
        if (err.json) {
            err.json().then(errObj => reject(errObj));
        } else {
            reject({
                status: err.status,
                response: err.response
            });
        }
    });
}

// endregion

export { get, post, patch, remove };
