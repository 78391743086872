/*
 * Copyright 2018-2020 by Avid Technology, Inc.
 */

import styles from './styles/login-tpl.scss';
import redirect from '../main/redirect';
import { customerLogoUrl } from '../../../configs/logoUrl';

function createDiv() {
    return document.createElement('div');
}

function createStylesElement({ el = 'div', classNames, id }) {
    const element = document.createElement(el);
    element.classList.add(...classNames);
    if (id) {
        element.setAttribute('id', id);
    }
    return element;
}

function createCustomerLogo() {
    const customerLogoContainer = createDiv();
    customerLogoContainer.classList.add(styles['customer-logo-container']);
    customerLogoContainer.setAttribute('id', 'customer-logo-container');

    const logoImage = document.createElement('img');
    logoImage.src = customerLogoUrl;
    logoImage.alt = 'logo';
    logoImage.classList.add(styles['customer-logo']);
    logoImage.onerror = () => {
        document.getElementById('customer-logo-container').classList.add(styles.hidden);
    };
    customerLogoContainer.appendChild(logoImage);
    return customerLogoContainer;
}

function createBrowserNotice() {
    const loginBrowserNotice = createStylesElement({
        classNames: ['mcux-login-browser-notice', styles['mcux-login-browser-notice']],
        id: 'mcux-login-browser-notice',
    });
    loginBrowserNotice.style.display = 'none';

    const loginNoticeMessage = createStylesElement({
        el: 'p',
        classNames: ['mcux-login-browser-message', styles['mcux-login-browser-message']],
        id: 'mcux-login-browser-message',
    });
    loginNoticeMessage.innerText = '';

    const supportedBrowsersLabel = document.createElement('p');
    supportedBrowsersLabel.innerText = 'Supported browsers and operating systems:';

    const supportedBrowsersList = document.createElement('ul');
    const supportedBrowserElement = document.createElement('li');
    supportedBrowserElement.innerHTML = '<b>Chrome</b> on Windows and Mac OS';
    supportedBrowsersList.appendChild(supportedBrowserElement);

    loginBrowserNotice.appendChild(loginNoticeMessage);
    loginBrowserNotice.appendChild(supportedBrowsersLabel);
    loginBrowserNotice.appendChild(supportedBrowsersList);

    return loginBrowserNotice;
}

function createIcon(id) {
    const iconContainer = createStylesElement({
        classNames: ['mcux-login-icon-container', styles['mcux-login-icon-container']],
    });

    const userNameIcon = createStylesElement({
        el: 'i',
        classNames: ['mcux-login-icon', styles['mcux-login-icon'], styles[id]],
        id,
    });

    iconContainer.appendChild(userNameIcon);
    return iconContainer;
}

function createUserNameSection() {
    const formSectionUserNameContainer = createStylesElement({
        el: 'p',
        classNames: ['mcux-paragraph', styles['mcux-paragraph']],
    });

    const userNameInput = createStylesElement({
        el: 'input',
        classNames: ['mcux-login-textbox', styles['mcux-login-textbox']],
        id: 'name',
    });
    userNameInput.type = 'text';
    userNameInput.name = 'name';
    userNameInput.placeholder = 'Username';
    userNameInput.autoComplete = 'off';
    userNameInput.spellcheck = false;
    userNameInput.autoFocus = true;

    formSectionUserNameContainer.appendChild(createIcon('mcux-login-icon-username'));
    formSectionUserNameContainer.appendChild(userNameInput);

    return formSectionUserNameContainer;
}

function createPasswordSection() {
    const formSectionUserPasswordContainer = createStylesElement({
        el: 'p',
        classNames: ['mcux-paragraph', styles['mcux-paragraph']],
    });

    const userPasswordInput = createStylesElement({
        el: 'input',
        classNames: ['mcux-login-textbox', styles['mcux-login-textbox']],
        id: 'password',
    });
    userPasswordInput.type = 'password';
    userPasswordInput.name = 'password';
    userPasswordInput.placeholder = 'Password';
    userPasswordInput.autoComplete = 'off';

    formSectionUserPasswordContainer.appendChild(createIcon('mcux-login-icon-password'));
    formSectionUserPasswordContainer.appendChild(userPasswordInput);

    return formSectionUserPasswordContainer;
}

function createSubmitButton() {
    const submitButtonContainer = createStylesElement({
        el: 'p',
        classNames: ['mcux-paragraph', styles['mcux-paragraph']],
    });

    const submitButton = createStylesElement({
        el: 'input',
        classNames: ['mcux-submit-button', styles['mcux-submit-button']],
        id: 'loginButton',
    });

    submitButton.type = 'submit';
    submitButton.value = 'Sign In';

    submitButtonContainer.appendChild(submitButton);
    return submitButtonContainer;
}

function createForm() {
    const form = createStylesElement({
        el: 'form',
        classNames: ['mcux-login-form', styles['mcux-login-form']],
        id: 'mcux-login-form',
    });
    form.style.visibility = 'hidden';

    const formAdminHeader = createStylesElement({
        el: 'p',
        classNames: ['mcux-login-admin-header', styles['mcux-login-admin-header']],
        id: 'login-admin-header',
    });

    formAdminHeader.style.display = 'none';
    formAdminHeader.innerText = 'Administrator';

    const loginError = createStylesElement({
        el: 'p',
        classNames: ['mcux-login-error', styles['mcux-login-error']],
        id: 'mcux-login-error',
    });
    loginError.style.display = 'none';
    loginError.innerText = '';

    form.appendChild(formAdminHeader);
    form.appendChild(createBrowserNotice());
    form.appendChild(loginError);
    form.appendChild(createUserNameSection());
    form.appendChild(createPasswordSection());
    form.appendChild(createSubmitButton());

    return form;
}

function createLogInArea() {
    const mcuxArea = createStylesElement({
        classNames: ['mcux-area', styles['mcux-area']]
    });

    const title = createStylesElement({
        classNames: ['title', styles.title],
        id: 'title',
    });

    const loginLoadingOuter = createStylesElement({
        classNames: ['mcux-loading-outer', styles['mcux-loading-outer']],
        id: 'mcux-login-loading-outer',
    });

    const loginLoadingInner = createStylesElement({
        classNames: ['mcux-loading-inner', styles['mcux-loading-inner']],
        id: 'mcux-login-loading-inner',
    });

    loginLoadingOuter.appendChild(loginLoadingInner);

    const form = createForm();

    mcuxArea.appendChild(loginLoadingOuter);
    mcuxArea.appendChild(title);
    mcuxArea.appendChild(form);

    return mcuxArea;
}

export default () => {
    const container = createDiv();
    container.classList.add(styles['login-page']);
    container.setAttribute('id', 'login-page');

    const loginBgLeft = createDiv();
    loginBgLeft.classList.add(styles['login-background-left']);

    const loginBgRight = createDiv();
    loginBgRight.classList.add(styles['login-background-right']);

    const logo = createStylesElement({
        classNames: [styles.logo],
        id: 'logo',
    });
    logo.onclick = redirect;

    const customerLogo = createCustomerLogo();

    const loginForm = createStylesElement({
        classNames: ['form-container', styles['form-container']],
    });

    loginForm.appendChild(createLogInArea());

    container.appendChild(loginBgLeft);
    container.appendChild(loginBgRight);
    container.appendChild(logo);
    container.appendChild(customerLogo);
    container.appendChild(loginForm);

    return container;
};
