/*
 * Copyright 2018 by Avid Technology, Inc.
 */

import hal from 'halfred';
import { identityProviderConfig } from './config';

const getEmbedded = (item, embedName) => {
    const resource = hal.parse(item);
    return resource.embeddedArray(embedName);
};

const getRopcProviderLink = (provider) => {
    return provider.link(identityProviderConfig.DEFAULT_PROVIDER_KEY);
};

const getDefaultProvider = (providers) => {
    return (providers || []).find((item) => {
        return getRopcProviderLink(item);
    });
};

export default (data) => {
    const result = {
        isValid: false,
        link: '',
        message: ''
    };
    const providersEmbedded = getEmbedded(data, identityProviderConfig.PROVIDERS_KEY);
    const defaultProvider = getDefaultProvider(providersEmbedded);
    if (!defaultProvider) {
        console.warn('[Identity Provider] No one identity provider has been configured.');
        result.message = identityProviderConfig.REQUEST_ERROR_TEXT;
        return result;
    }
    const link = getRopcProviderLink(defaultProvider);
    if (!link || !link.href) {
        console.warn('[Identity `Provider] No default identity provider has been configured.');
        result.message = identityProviderConfig.REQUEST_ERROR_TEXT;
        return result;
    }
    return {
        isValid: true,
        link: link.href,
        message: ''
    };
};
