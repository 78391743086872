/*
 * Copyright 2018-2020 by Avid Technology, Inc.
 */

export const eulaKind = 'eula-em-settings';
export const eulaKey = 'eula-settings';
export const AUTH_PATH = '/auth/tokens/current';
export const AUTH_PATH_EXTEND = `${AUTH_PATH}/extension`;
export const DEFAULT_LOCALE = 'en';
export const ADMIN_CONTEXT_ID = '00000000-0000-4000-a000-000000000000';
export const AUTHORIZATIONS_PATH = '/apis/avid.iam;version=3;realm=global/authorizations';
