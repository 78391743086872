/*
 * Copyright 2018, 2020 by Avid Technology, Inc.
 */

const loginErrors = {
    WRONG_CREDENTIALS: {
        status: 400,
        error: 'invalid_grant',
        message: 'The user name or password you entered is incorrect.'
    },
    LICENSE_QUOTA_EXCEEDED: {
        status: 402,
        code: 'avid.iam/QUOTA_EXCEEDED',
        message: 'Your MediaCentral | CloudUX Platform licenses are all in use. Please contact your administrator.'
    },
    USER_NOT_IN_GROUP: {
        status: 404,
        code: 'avid.iam/NOT_FOUND',
        message: 'The user name or password you entered is incorrect.'
    },
    LICENSE_SEATS_ENTITLEMENT_EXPIRED: {
        status: 410,
        code: 'avid.iam/SEATS_ENTITLEMENT_EXPIRED',
        message: 'Your MediaCentral | CloudUX Platform license expired. Please contact your administrator.'
    },
    TIMEOUT_USER_AUTHENTICATION: {
        status: 503,
        code: 'avid.iam/SERVICE_UNAVAILABLE',
        params: {
            reason: 'idp_timeout'
        },
        message: 'The login call timed out. Please try again in a few minutes. Please contact your administrator if the issue persists.'
    },
    ACCOUNT_LOCKED_OR_DISABLED: {
        status: 403,
        code: 'avid.iam/FORBIDDEN',
        params: {
            reason: 'account_locked_or_disabled_in_idp',
        },
        message: 'Your account has been locked or disabled. To access your account, please contact your administrator.'
    },
};

const validateMessages = {
    WRONG_CREDENTIALS: 'The user name or password you entered is incorrect.',
};

export {
    loginErrors,
    validateMessages
};
