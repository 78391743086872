/*
 * Copyright 2017, 2019-2020 by Avid Technology, Inc.
 */

import hal from 'halfred';
import Registry from './registry';

function getSimpleLink(item, linkName) {
    const resource = hal.parse(item);
    const linkObj = resource.link(linkName);
    return linkObj && linkObj.href;
}

function getCollectionLink(item) {
    return getSimpleLink(item, 'loc:collection');
}

function getCollectionWithIdLink(item) {
    return getSimpleLink(item, 'loc:collection-at-itemid');
}

function getPathLink(item) {
    return getSimpleLink(item, 'loc:path-to-root');
}

function getReferencingItemsLink(item) {
    return getSimpleLink(item, 'loc:referencing-items');
}

function getCreateBinLink(item) {
    return getSimpleLink(item, 'editorial-management:create-bin');
}

function getCreateFolderLink(item) {
    return getSimpleLink(item, 'loc:create-folder');
}

function getDeleteItemLink(item) {
    return getSimpleLink(item, 'loc:delete-item-by-id');
}

function getLocationLink(item) {
    return getSimpleLink(item, 'loc:locations');
}

function getUpdateLink(item) {
    return getSimpleLink(item, 'loc:update-item');
}

function getAddItemsLink(item) {
    return getSimpleLink(item, 'loc:add-items');
}

function getMoveItemsLink(item) {
    return getSimpleLink(item, 'loc:move-items');
}

function getRootItemLink(item) {
    return getSimpleLink(item, 'loc:root-item');
}

function getDataModel(item) {
    const dataModel = hal.parse(item);
    return dataModel.resources['datamodel:aggregated-model'][0];
}

function getPath(item) {
    const resource = hal.parse(item);
    return resource.embeddedArray('loc:path') || [];
}

function filterBrowsableSystems(resources, system) {
    const resource = hal.parse(resources);
    return isSystemAvailableForLink(resource, system, 'loc:root-item')
        || isSystemAvailableForLink(resource, system, 'loc:locations');
}

function isSystemAvailableForLink(resources, system, linkName) {
    const resourceInfos = resources[linkName] || [];
    return resourceInfos.some(resourceInfo => getLinkByBaseData(resourceInfo.systems, system));
}

function getLinks(links) {
    let results = [];
    Array.isArray(links) && links.forEach((link) => {
        const { href } = link;
        link.systems.forEach((system) => {
            const { systemID, systemType } = system;
            results.push({ href, systemType, systemID });
        });
    });
    return results;
}

function getLinkByBaseData(links, baseData) {
    return links.find((link) => {
        return link.systemID === baseData.systemID &&
            link.systemType === baseData.systemType;
    });
}

function getCreateFolderRequestConfig(data) {
    const body = {
        common: {
            name: data.name || 'New.Folder'
        }
    };
    // eslint-disable-next-line no-prototype-builtins
    const autoincrement = data.hasOwnProperty('autoincrement')
        ? data.autoincrement
        : true;
    return {
        body,
        autoincrement
    };
}

function getLinksByType(type) {
    return Registry.getServiceRoots()
        .then(response => {
            const links = response.resources[type];
            return getLinks(links);
        });
}

export {
    getSimpleLink,
    filterBrowsableSystems,
    getCollectionLink,
    getCollectionWithIdLink,
    getPathLink,
    getCreateFolderLink,
    getDeleteItemLink,
    getLocationLink,
    getUpdateLink,
    getAddItemsLink,
    getMoveItemsLink,
    getRootItemLink,
    getReferencingItemsLink,
    getDataModel,
    getPath,
    getLinks,
    getLinkByBaseData,
    getCreateFolderRequestConfig,
    getCreateBinLink,
    getLinksByType
};
