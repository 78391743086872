/*
 * Copyright 2018 by Avid Technology, Inc.
 */

const headers = new Headers();
headers.append('Content-Type', 'application/x-www-form-urlencoded');
headers.append('Accept', 'application/json');
headers.append('Authorization', 'Basic Y29tLmF2aWQubWVkaWFjZW50cmFsY2xvdWQtYTk1YjNmOTM4NDk3NWE1MzVjZTAwNTMzMTUzMWY2ZmI6Yzg2M2' +
    'JhNmM0ZTAzZDM1NWEzZDI5NjY0MWQ2NTY2ZWYzZTU0Y2ZiZTU1MzQ5NjliNWY3MmY1YmQ5NTZhNzE5MQ==');

export default () => fetch('/auth/identity-providers', {
    method: 'get',
    credentials: 'same-origin',
    headers
});
