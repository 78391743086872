/*
 * Copyright 2018 by Avid Technology, Inc.
 */

const identityProviderConfig = {
    DEFAULT_PROVIDER_KEY: 'auth:ropc-default',
    PROVIDERS_KEY: 'auth:identity-provider',
    REQUEST_ERROR_TEXT: '<p>No default identity provider has been configured.</p><p>Please contact your Administrator.</p>'
};

export { identityProviderConfig };
