/*
 * Copyright 2014-2019 by Avid Technology, Inc.
 */

import { OS, browserName, browserVersion, isQtClient, isChrome, isWindows, isMac } from './browserAndOsDetection';

export function getBrowserWarning() {
    let browserNameAndVersion;

    // Strings are not localized as they weren't localized in previous implementation. They can be localized in future.
    const osWarning = 'Your operating system';
    const messageEnd = 'is currently not supported by MediaCentral\xa0|\xa0UX.';
    const onOs = 'on';

    const space = ' ';

    if (!isQtClient()) {
        if (!isWindows && !isMac) {
            return [osWarning, messageEnd].join(space);
        }

        if (!isChrome) {
            if (browserName) {
                browserNameAndVersion = browserName;
            }
            if (browserVersion && browserName) {
                browserNameAndVersion += ` ${browserVersion}`;
            }

            return [browserNameAndVersion, onOs, OS, messageEnd].join(space);
        }
    }
}
