/*
 * Copyright 2018-2019 by Avid Technology, Inc.
 */

import loginForm from '../form/login-form';
import isAdminLoginCheck from '../admin/isAdminLoginCheck';
import { getBrowserWarning } from './notificationAboutUserEnvironment';
import { validateMessages } from '../messages';
import tryLogin from './try-login';
import documentLoading from '../../module-loader/main/load-modules/document-loading.js';

const validate = ({ username, password }) => {
    let result = { isValid: true };
    if (!username || !password) {
        result = { isValid: false, message: validateMessages.WRONG_CREDENTIALS };
    }
    return result;
};

const loginManager = {
    initLoginForm() {
        return new Promise((resolve) => {
            loginForm.render();
            return documentLoading().then(() => {
                if (isAdminLoginCheck()) {
                    loginForm.addLoginAdminStyle();
                }
                loginForm.show();
                loginForm.hideLoading();
                const browserWarning = getBrowserWarning();
                if (browserWarning) {
                    loginForm.setBrowserWarning(browserWarning);
                }
                loginForm.onSubmit((event) => {
                    event.preventDefault();
                    if (!loginManager.validateForm()) {
                        return;
                    }
                    return loginManager.loginHandler(resolve);
                });
            });
        });
    },

    loginHandler(resolve) {
        const { username, password } = loginManager.getFormData();
        const isAdministrator = isAdminLoginCheck();
        loginManager.showFormLoadingMessage();
        return tryLogin({ username, password, isAdministrator })
            .then((loginResult) => {
                if (!loginResult) {
                    return Promise.reject();
                }
                if (loginResult.isValid) {
                    loginManager.destroyLoginForm();
                    resolve();
                } else {
                    loginManager.hideFormLoading();
                    loginManager.showRequestMessage(loginResult.message);
                }
            })
            .catch((err) => {
                console.warn('Login error: ', err && err.message);
                loginManager.hideFormLoading();
                loginManager.showFormRequestError();
            });
    },

    showRequestMessage(msg) {
        if (msg) {
            loginManager.showRequestError(msg);
        } else {
            loginManager.showFormRequestError();
        }
    },

    destroyLoginForm() {
        loginForm.destroy();
        loginForm.renderSpinner();
    },

    getFormData() {
        return {
            username: loginForm.getName(),
            password: loginForm.getPassword(),
        };
    },

    validateForm() {
        const { username, password } = loginManager.getFormData();
        loginForm.cleanError();
        const validationResult = validate({ username, password });
        if (!validationResult.isValid) {
            loginForm.showError(validationResult.message);
            return false;
        }
        return true;
    },

    showFormLoadingMessage(msg) {
        loginForm.showLoadingMessage(msg);
    },

    hideFormLoading() {
        loginForm.hideLoading();
    },

    showFormRequestError(err) {
        loginForm.hideLoading();
        loginForm.showRequestError(err);
    },

    showRequestError(message) {
        loginForm.show();
        loginForm.showError(message);
    }
};

export default loginManager;
